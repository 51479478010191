import { Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const EventsTable = ({events}) => {
  
  const stages = {
    1: 'Inscrieri',
    2: 'Grupe Inferioare',
    3: 'Grupe Superioare',
    4: 'Eliminatorii',
    5: 'Incheiat'
  }
  if (!events) return <div>Loading</div>
  return (
    <Table size='sm' responsive className='customer-events-table'>
      <thead>
        <tr>
          <th>Data si Ora</th>
          <th>Nume</th>
          <th>Judet</th>
          <th>Stagiu</th>
          <th>Jucatori Confirmati</th>
          <th>Lista de asteptare</th>
        </tr>
      </thead>
      <tbody>
        { events.map((event) => {
          const date = new Date(event.date);
          const year = date.getFullYear();
          const month = date.getMonth() + 1; // Months are 0-based, so add 1
          const day = date.getDate();
          
          // Extract time
          const hours = date.getUTCHours();
          const minutes = date.getUTCMinutes();

          const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
          const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;

          return (
            <tr key={event.id}>
              <td>{formattedDate} | {formattedTime}</td>
              <td><Link to={`events/${event.id}`}>{event.name}</Link></td>
              <td>{event.county}</td>
              <td>{stages[event.eventStageId]}</td>
              <td>{event.confirmedPlayers.length} / {event.maxPlayers}</td>
              <td>{event.waitlistedPlayers.length}</td>
            </tr>
          )
        })
        }
      </tbody>
    </Table>
  )
}

export default EventsTable;