import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { fetchCustomerData } from "../../actions/customer_dashboard"
import EventsTable from "./events_table"
import { selectCustomer, selectCustomerStatus } from "../../selectors/customer_dashboard"
import { LoadingPage } from "../shared/loading_page"
import { Col, Row, Button } from "react-bootstrap"
import { Managers } from "./managers"
import CustomerInfoForm from "./customer_info_form"
import MobileEventsTable from "./mobile_events_table"

export const CustomerRoot = () => {

  const status = useSelector(selectCustomerStatus)
  const customer = useSelector(selectCustomer)

  const isMobile = window.innerWidth < 768
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchCustomerData())
  }, [dispatch])

  if (status === 'loading') return <LoadingPage/>
  if (status === 'error') return <div>Error</div>
  if (status === 'success')
    return (
      <div className="margin-1">
        <Row className="margin-top-1">
          <Col md={4} xs={12}>
            <CustomerInfoForm customer={customer}/>
          </Col>
          <Col md={{span: 4, offset: 2}} xs={12}>
            <Managers managers={customer.managers}></Managers>
          </Col>
        </Row>
        <div className="customer-events-table-container">
          <Row className="margin-top-1">
            <Col md={1}>
              <p className="large-text bold">Evenimente</p>
            </Col>
            <Col md={{offset: 1, span: 2}}>
              <Button href='/events/new' className="primary margin-bottom-1">Creeaza eveniment nou</Button>
            </Col>
          </Row>

          { isMobile
            ?
            <MobileEventsTable events={customer.events}/>
            :
            <EventsTable events={customer.events}></EventsTable>
          }
        </div>

        
      </div>
    )

}
