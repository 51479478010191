import IconButton from "../shared/icon_button"
import { useState } from "react";
import AddManagerModal from "./add_manager_modal";
import { removeManager } from "../../actions/customer_dashboard";
import { useDispatch } from "react-redux";

export const Managers = ({ managers }) => {
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const handleAddManager = () => {
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <>
      <span className="large-text bold">Administratori</span>
      <table className="managers-table">
        <tr>
          <td colSpan={3}>
            <IconButton height={38} width={38} icon="add" onClick={handleAddManager} />
          </td>
        </tr>

        {managers.map((manager) => (
          <tr key={manager.id}>
            <td className="medium-text">{manager.firstName} {manager.lastName}</td>
            <td className="medium-text">{manager.phone}</td>
            <td className="center-content">
              <IconButton height={26} width={26} icon="delete" onClick={() => dispatch(removeManager(manager.id))}></IconButton>
            </td>
          </tr>
        ))}
      </table>

      {showModal && <AddManagerModal show={showModal} close={handleClose} />}
    </>
  );
};
