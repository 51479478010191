import storage from 'redux-persist/lib/storage/session';

const persistConfig = {
  key: 'root', // Key to store data in sessionStorage
  storage, // Use sessionStorage for storage
  whitelist: ['auth'], // Specify the reducers you want to rehydrate
  // Add any other config options you need here
};

export default persistConfig;
