import { Bracket } from "./bracket";
import { Winner } from "../winner";
import { useSelector } from "react-redux";
import { selectWinner } from "../../../../../selectors/event_page";

export const Brackets = ({rounds, currentRoundId, finishedEvent}) => {

  const winner = useSelector(selectWinner);

  return (
    <div className='brackets-container'>
      {
        rounds.map((round, index) => {return (
          <Bracket key={round.id} round={round} index={index} isCurrentRound={round.id === currentRoundId && !finishedEvent}></Bracket>
        )}
        )
      }
      { finishedEvent && <Winner player={winner}></Winner>}
    </div>
  );

};

