import { Card, FormControl, ListGroup, ListGroupItem, Form, Button } from "react-bootstrap"
import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { addPlayer } from "../../../../actions/event_page/registration";
import IconButton from "../../../shared/icon_button";

export const AddPlayerButton = ({destination, eventId, disabled}) => {

  const [ editing, setEditing ] = useState(false);
  const [ phone, setPhone ] = useState();
  const [ name, setName ] = useState();
  const [ city, setCity ] = useState();
  const [ invalidInput, setInvalidInput ] = useState(false);
  const dispatch = useDispatch();

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!name || !phone || !city) {
      setInvalidInput(true)
    }
    else {
      const player = {
        name,
        phone,
        city,
        eventId,
      }
      dispatch(addPlayer(player, destination, true))
      setEditing(false);
    }
  }
  
  return (
    editing ? 
    (
      <Card>
        <Form>
          <ListGroup className={invalidInput && 'red-border'}horizontal>
            <IconButton extraStyling={"border-1"} height={18} width={18} transparent={true} icon={'close'} onClick={() => setEditing(false)}></IconButton>
            <ListGroupItem className="border-1">
              <FormControl placeholder='Nume Jucator' onChange={(event) => {setName(event.target.value)}} plaintext autoFocus></FormControl>
            </ListGroupItem>
            <ListGroupItem className="border-1">
              <FormControl placeholder='Numar de telefon' onChange={(event) => {setPhone(event.target.value)}} plaintext ></FormControl>
            </ListGroupItem>
            <ListGroupItem className="border-1">
              <FormControl placeholder='Oras' onChange={(event) => {setCity(event.target.value)}} plaintext ></FormControl>
            </ListGroupItem>
            <IconButton type='submit' extraStyling={"border-1"} height={18} width={18} transparent={true} icon={'check'} onClick={handleSubmit}></IconButton>
          </ListGroup>
        </Form>
      </Card>
    ) 
    :
    (
      <Button disabled={disabled} variant='light' onClick={() => {setEditing(true)}} className="add-player-button turn-primary-on-hover">
        Adauga Jucator
      </Button>
    )
  );
}