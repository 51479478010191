export const selectGroups = (state, roundId) => {
  const rounds = state.eventPage.play.rounds;
  if (!rounds || rounds.length === 0) {
    return [];
  }
  const round = rounds.find(round => round.id === roundId);
  return round ? round.groups : [];
};

export const selectCurrentRoundGroups = (state) => {
  const rounds = state.eventPage.play.rounds;
  if (!rounds || rounds.length === 0) {
    return [];
  }
  return rounds[rounds.length - 1].groups;
};