import {
  FETCH_EVENTS_STARTED,
  FETCH_EVENTS_SUCCEEDED,
  FETCH_EVENTS_FAILED,
  SELF_REGISTER_PLAYER_SUCCEEDED,
  SELF_REGISTER_PLAYER_REQUEST,
  SELF_REGISTER_PLAYER_FAILED,
  CHANGE_FILTERS,
  RESET_ERROR_STATE,
} from '../../actions/home_page'

const initialState = {
  status: 'uninitialized',
  events: [],
  error: null,
  filters: {
    name: '',
    stage: '',
    startDate: '',
    endDate: '',
    date: '',
    county: '',
  },
}

export const homePageReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_EVENTS_STARTED: {
      return {
        ...state,
        status: 'loading'
      }
    }
    case FETCH_EVENTS_SUCCEEDED: {
      return {
        ...state,
        status: 'success',
        events: action.events,
      }
    }
    case FETCH_EVENTS_FAILED: {
      return {
        ...state,
        status: 'failed',
        events: [],
        error: action.error
      }
    }
    case SELF_REGISTER_PLAYER_SUCCEEDED: {
      return {
        ...state,
        status: 'success',
        events: state.events.map(event => {
          if (event.id === action.eventId) {
        return {
          ...event,
          confirmedPlayers: action.data.confirmedPlayers,
          waitlistedPlayers: action.data.waitlistedPlayers,
          rejectedPlayers: action.data.rejectedPlayers,
        };
          }
          return event;
        }),
      };
    }
    case SELF_REGISTER_PLAYER_REQUEST: {
      return {
        ...state,
        status: 'loading'
      }
    }
    case SELF_REGISTER_PLAYER_FAILED: {
      return {
        ...state,
        status: 'failed',
        events: [],
        error: action.error
      }
    }
    case CHANGE_FILTERS: {
      return {
        ...state,
        filters: action.filtersData,
      }
    }
    case RESET_ERROR_STATE: {
      return {
        ...state,
        error: null,
      }
    }
    default:
      return state
  }
}