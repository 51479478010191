import React, { useEffect } from 'react';
import { Carousel, Col } from 'react-bootstrap';
import { useState } from 'react';
import { fetchRounds } from '../../../actions/event_page/play_tab/rounds';
import { useDispatch, useSelector } from 'react-redux';
import { selectRounds, selectCurrentRound } from '../../../selectors/round';
import { selectWinner } from '../../../selectors/event_page';
import RoundInfo from './round_info';

const RoundsInfo = ({finishedEvent}) => {

  const rounds = useSelector(selectRounds);
  const winner = useSelector(selectWinner);
  const [index, setIndex] = useState(rounds.length - 1);
  const dispatch = useDispatch();
  
  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  const currentRound = useSelector(selectCurrentRound)

  useEffect(() => {
    dispatch(fetchRounds());
  },[dispatch])

  useEffect(() => {
    if (currentRound && rounds.length > 0) {
      const tabIndex = rounds.findIndex(round => round.id === currentRound.id)
      if (finishedEvent)
        setIndex(rounds.length)
      else
        setIndex(tabIndex)
    }
  }, [currentRound, rounds])

  return (
    <Col xs={12} md={4} className="detailed-event-column">
      <div className="large-text bold primary-color border-bottom margin-bottom-1">Faza de joc</div>
      <Carousel className='custom-carousel' activeIndex={index} onSelect={handleSelect} variant='dark' interval={null}>
        {rounds.map((round, index) => (
          <Carousel.Item key={index}>
            <RoundInfo round={round} index={index} />
          </Carousel.Item>
        ))}
        { finishedEvent && 
          <Carousel.Item>
            <div className='slide-container'>
              <div className='xxl-text bold primary-color margin-bottom-1'>Castigator</div>
              <div className='xxl-text bold'>{winner.name}</div>
            </div>
          </Carousel.Item>
        }
      </Carousel>
    </Col>
  );
};

export default RoundsInfo;
