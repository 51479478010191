import { Form, Col, Row } from 'react-bootstrap'
import React, { useState } from 'react';
import Button from 'react-bootstrap/esm/Button';
import { addEvent, editEvent } from '../../../actions/event_page/event_info'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { CountyFilter } from '../../shared/county_filter';
import { useSelector } from 'react-redux';
import { selectCustomer } from '../../../selectors/customer_dashboard';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import dayjs from 'dayjs';

const EventForm = ({event={}}) => {

  const dispatch = useDispatch();
  const customer = useSelector(selectCustomer)
  const isCreating = Object.keys(event).length === 0;
  const [name, setName] = useState(event.name || null)
  const [date, setDate] = useState(event.date ? new Date(event.date) : new Date());
  const [closeDate, setCloseDate] = useState(event.registration_close_date ? new Date(event.registration_close_date) : new Date());
  const [location, setLocation] = useState(event.location)
  const [maxPlayers, setMaxPlayers] = useState(event.max_players)
  const [confirmation, setConfirmation] = useState(event.needs_confirmation)
  const [privateEvent, setPrivateEvent] = useState(event.private)
  const [description, setDescription] = useState(event.description)
  const [ county, setCounty ] = useState(event.county)
  const isMobile = window.innerWidth < 768;
  const navigate = useNavigate()

  const handleSubmit = () => {
    var new_event = {
      name: name,
      date: date,
      location: location,
      county: county,
      max_players: maxPlayers,
      description: description,
      needs_confirmation: confirmation,
      registration_close_date: closeDate,
      private: privateEvent,
      eventStageId: 1,
    }
    dispatch(isCreating ? addEvent(new_event) : editEvent(event.id, new_event))
    navigate(`/customers/${customer.id}`)
  }


  return (
    <Form className='event-form rounded-padded padding-2'>
      <p className='xxl-text extra-bold margin-bottom-1 border-bottom-1'>{ isCreating ? 'Creare Eveniment' : 'Editare Eveniment'}</p>
      <Row>
        <Col md={7}>
        <div className='general-information-div'>
            <p className='large-text bold'>Informatii Generale</p>
            <Row className='margin-bottom-1'>
              <Col>
                <Form.Label>Nume Eveniment:</Form.Label>
                <Form.Control value={name} onChange={(e) => {setName(e.target.value)}}></Form.Control>
              </Col>
            </Row>
            <Row className='margin-bottom-1 border-bottom-1 padding-bottom-1'>
              <Col md={4}>
                <CountyFilter county={county} setCounty={setCounty}></CountyFilter>
              </Col>
              <Col>
                <Form.Label>Adresa:</Form.Label>
                <Form.Control value={location} onChange={(e) => {setLocation(e.target.value)}}></Form.Control>
              </Col>
            </Row>
            <br></br>
            <Row className='margin-bottom-1'>
              <Col md={6} xs={12} className={`center-content-h ${isMobile && 'padding-bottom-1'}`}>
                <DateTimePicker
                  label="Data Eveniment"
                  value={dayjs(date)}
                  onChange={(newValue) => {
                    setDate(newValue);
                  }}
                  ampm={false}
                  format="DD/MM/YYYY | HH:mm"
                />
              </Col>
              <Col md={6} xs={12} className='center-content-h'>
                <DateTimePicker
                  label="Data Inchidere Inscrieri"
                  value={dayjs(closeDate)}
                  onChange={(newValue) => {
                    setCloseDate(newValue);
                  }}
                  ampm={false}
                  format="DD/MM/YYYY | HH:mm"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Check 
                  type='checkbox' 
                  id='private-check' 
                  label='Eveniment privat'
                  checked={privateEvent}
                  onChange={() => {setPrivateEvent(!privateEvent)}}
                />
              </Col>
              <Col>
                <i className="fa fa-question-circle margin-left-1" title="Daca turneul este privat, acesta nu va fi afisat pe prima pagina. Evenimentele private se pot publica ulterior."></i>
              </Col>
            </Row>
          </div>
          <p className='large-text bold'>Jucatori</p>

          <div className='players-information-div'>
            <Row>
              <Col md={2} xs={5} className='center-content-v max-nr-column'><Form.Label>Numar maxim:</Form.Label></Col>
              <Col md={2} xs={2} className='center-content-v no-padding'><Form.Control value={maxPlayers} onChange={(e) => {setMaxPlayers(e.target.value)}}></Form.Control></Col>
              <Col md={{offset: 1, span: 4}} xs = {{offset: 1, span: 4}} className='center-content'>
                <Form.Check 
                  type='checkbox' 
                  id='confirmation-check' 
                  label='Necesita confirmare'
                  checked={confirmation}
                  onChange={() => {setConfirmation(!confirmation)}}
                />
                <i className="fa fa-question-circle margin-left-1" title="Daca turneul necesita confirmare, jucatorii vor fi inscrisi pe lista de asteptare pana cand organizatorii le confirma participarea."></i>
              </Col>
            </Row>
          </div>
        </Col>
        <Col className='no-padding' md={{span: 5}}>
          <Row>
            <Col>      
              <p className='large-text bold'>Descriere</p>
              <ReactQuill
                className='text-editor'
                value={description}
                onChange={(content) => setDescription(content)}
                modules={{
                  toolbar: [
                    ['bold', 'italic', 'underline', 'strike'],
                    [{ list: 'ordered' }, { list: 'bullet' }],
                    ['link', 'image', 'video'],
                    ['clean'],
                  ],
                }}
                formats={[
                  'bold', 'italic', 'underline', 'strike',
                  'list', 'bullet',
                  'link', 'image', 'video',
                ]}
                rows={18}
                >
              </ReactQuill>
              <div className='d-flex justify-content-end'>
                <Button href={`/customers/${customer.id}`} className='margin-top-1 accent no-border'>Anulare</Button>
                <Button className='margin-top-1 margin-left-d5 primary no-border' onClick={handleSubmit}>{isCreating ? 'Creare' : 'Salvare'}</Button>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  )
    
}

export default EventForm