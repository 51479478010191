import { Modal } from "react-bootstrap"
import { Match } from "./match"
import {Tab, Tabs} from '@mui/material'
import { useState, useEffect } from "react"
import { GroupLeaderboard } from "./group_leaderboard"
import { useDispatch } from "react-redux"
import { fetchGroupData } from "../../../../../actions/event_page/play_tab/groups"

export const ScoresModal = ({show, handleClose, index, group, readOnly}) => {

  const [activeTab, setActiveTab] = useState(0)
  const dispatch = useDispatch()
  
  const players = group.instances || []
  const matches = group.matches || []

  useEffect(() => {
    if(show)
      dispatch(fetchGroupData(group.roundId, group.id))
  }, [show])

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <Modal show={show} onHide={handleClose} scrollable={true}>
      <Modal.Header closeButton>
        <Modal.Title> Grupa {index}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Tabs value={activeTab} onChange={handleChange}>
          <Tab label="Scoruri"></Tab>
          <Tab label="Clasament"></Tab>
        </Tabs>
        {activeTab === 0 && <div>
          {matches.map((match) => (
            <Match match={match} readOnly={readOnly} group={group}/>
          ))}
        </div>
        }
        {activeTab === 1 && <div>
          <GroupLeaderboard players={players}/>
        </div>}
      </Modal.Body>
    </Modal>
  );
} 