import Event from "./event_component"
import { useSelector } from "react-redux"
import { selectEvents } from "../../selectors/home_page"
import MobileEvent from "./mobile_event_component";

export const EventsList = () => {

  const events = useSelector(selectEvents);
  const isMobile = window.innerWidth < 768;
  return (
    <div className={isMobile ?'mobile-events-container' : 'events-container'}>
      {events.map((event) => (
        isMobile ? (
          <MobileEvent key={event.id} event={event} />
        ) : (
          <Event key={event.id} event={event} />
        )
      ))}
    </div>
  )
}