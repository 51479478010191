import { useSelector, useDispatch } from "react-redux"
import { useState, useEffect, useRef } from "react"
import { EditPairingsForm } from "../pairings_tab/edit"
import { fetchGroupsForRound } from "../../../../actions/event_page/play_tab/groups"
import { selectGroups } from "../../../../selectors/groups"
import { Groups } from "./groups/root"

export const Round = ({round, readOnly}) => {

  const dispatch = useDispatch();
  const prevRoundRef = useRef();

  useEffect(() => {
    if (prevRoundRef.current !== round) {
      dispatch(fetchGroupsForRound(round));
      prevRoundRef.current = round;
    }
  }, [dispatch, round.groupsConfirmed]);

  const groups = useSelector(state => selectGroups(state, round.id));

  return( groups && (
    round.groupsConfirmed ?
    <Groups groups={groups} round={round} readOnly={readOnly}/>
    :
    <EditPairingsForm groups={groups} roundId={round.id}/>
  )
  )
}