import { Card, FormControl, ListGroup, ListGroupItem, Form, Button } from "react-bootstrap"
import React from "react"
import { useState } from "react"
import { useDispatch } from "react-redux"
import { addTestPlayers } from "../../../../actions/event_page/registration"
import IconButton from "../../../shared/icon_button"

export const AddTestPlayersButton = ({eventId, disabled}) => {

  const [ editing, setEditing ] = useState(false);
  const [ numberOfPlayers, setNumberOfPlayers ] = useState();
  const dispatch = useDispatch();

  const handleSubmit = (event) => {
    event.preventDefault();

    dispatch(addTestPlayers(numberOfPlayers, eventId))
    setEditing(false);

  }
  
  return (
    editing ? 
    (
      <Card>
        <Form>
          <ListGroup horizontal>
            <IconButton extraStyling={"border-1"} height={18} width={18} transparent={true} icon={'close'} onClick={() => setEditing(false)}></IconButton>
            <ListGroupItem className="border-1">
              <FormControl placeholder='Numar Jucatori' onChange={(event) => {setNumberOfPlayers(event.target.value)}} plaintext autoFocus></FormControl>
            </ListGroupItem>
            <IconButton type='submit' extraStyling={"border-1"} height={18} width={18} transparent={true} icon={'check'} onClick={handleSubmit}></IconButton>
          </ListGroup>
        </Form>
      </Card>
    ) 
    :
    (
      <Button disabled={disabled} variant='light' onClick={() => {setEditing(true)}} className="add-player-button turn-primary-on-hover">
        Adauga Jucatori de Test
      </Button>
    )
  );
}