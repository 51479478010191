import { SERVER_URL } from '../../server_config';

import { fetchEventData } from './event_info';

export const SELF_REGISTER_PLAYER_SUCCEEDED = 'SELF_REGISTER_PLAYER_SUCCEEDED'
export const SELF_REGISTER_PLAYER_FAILED = 'SELF_REGISTER_PLAYER_FAILED'
export const ADD_PLAYER_FAILED = 'ADD_PLAYER_FAILED'
export const ADD_PLAYER_SUCCEEDED = 'ADD_PLAYER_SUCCEEDED'
export const ADD_PLAYER_STARTED = 'ADD_PLAYER_STARTED'
export const MOVE_PLAYER_STARTED = 'MOVE_PLAYER_STARTED'
export const MOVE_PLAYER_SUCCEEDED = 'MOVE_PLAYER_SUCCEEDED'
export const MOVE_PLAYER_FAILED = 'MOVE_PLAYER_FAILED'
export const DELETE_PLAYER_SUCCEEDED = 'DELETE_PLAYER_SUCCEEDED'
export const DELETE_PLAYER_FAILED = 'DELETE_PLAYER_FAILED'
export const DELETE_PLAYER_STARTED = 'DELETE_PLAYER_STARTED'

export const addPlayerStarted = () => ({
  type: ADD_PLAYER_STARTED,
})

export const addPlayerFailed = error => ({
  type: ADD_PLAYER_FAILED,
  error,
})

export const addPlayerSucceeded = event => ({
  type: ADD_PLAYER_SUCCEEDED,
  response: event
})

export const movePlayerStarted = () => ({
  type: MOVE_PLAYER_STARTED,
})

export const movePlayerSucceeded = data => ({
  type: MOVE_PLAYER_SUCCEEDED,
  data
})

export const movePlayerFailed = error => ({
  type: MOVE_PLAYER_FAILED,
  error
})

export const deletePlayerSucceeded = data => ({
  type: DELETE_PLAYER_SUCCEEDED,
  data
})

export const deletePlayerFailed = error => ({
  type: DELETE_PLAYER_FAILED,
  error
})

export const deletePlayerStarted = () => ({
  type: DELETE_PLAYER_STARTED,
})

export const selfRegisterPlayerSucceeded = (event) => ({
  type: SELF_REGISTER_PLAYER_SUCCEEDED,
  eventData: event
})

export const selfRegisterPlayerFailed = (error) => ({
  type: SELF_REGISTER_PLAYER_FAILED,
  error
})

export const selfRegisterPlayer = (player) => {
  return async (dispatch, getState) => {

    const state = getState()
    if (state.eventPage.status === 'loading')
      return

    const body = {
      player: player,
      selfRegisteredPlayer: true,
    }


    const eventId = player.eventId
    // why is this here?
    fetch(`${SERVER_URL}/events/${eventId}/players`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body)
    })
    .then(response => {
      if (response.status === 409) {
        return response.json().then(data => {
          throw new Error(data.message);
        });
      }
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(response => { 
      dispatch(selfRegisterPlayerSucceeded(response.eventData))
    })
    .catch(error => {
      dispatch(selfRegisterPlayerFailed(error.message))
    });
  }
}

export const closeEventRegistrations = (eventId) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${SERVER_URL}/events/${eventId}/close_registrations`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      dispatch(fetchEventData(eventId));
    } catch (error) {
      console.log(error);
    }
  };
};

export const openEventRegistrations = (eventId) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${SERVER_URL}/events/${eventId}/open_registrations`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      dispatch(fetchEventData(eventId));
    } catch (error) {
      console.log(error);
    }
  };
};

export const addPlayer = (player, destination, adminAddedPlayer) => {
  return async (dispatch, getState) => {
    const state = getState()

    if (state.eventPage.status === 'loading')
      return

    dispatch(addPlayerStarted())

    const eventId = player.eventId

    const body = {
      player: player,
      destination: destination,
      adminAddedPlayer: adminAddedPlayer,
    }

    fetch(`${SERVER_URL}/events/${eventId}/players`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body)
    })
    .then(response => {
      if (response.status === 409) {
        return response.json().then(data => {
          throw new Error(data.message);
        });
      }
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(response => { 
      dispatch(addPlayerSucceeded(response.data))
    })
    .catch(error => {
      dispatch(addPlayerFailed(error.message))
    });
  }
}


export const movePlayer = (player, target) => {
  return async (dispatch) => {
    const body = {
      'target': target, 
    }

    const eventId = player.eventId
    const playerId = player.id
    dispatch(movePlayerStarted());
    
    try {
      const response = await fetch(`${SERVER_URL}/events/${eventId}/players/${playerId}/move_player`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json", 
        },
        credentials: "include",
        body: JSON.stringify(body),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const parsed_response = await response.json(); // Parse the response body as JSON
      dispatch(movePlayerSucceeded(parsed_response.data));
    } catch (error) {
      dispatch(movePlayerFailed(error));
    }
  }
}

export const addTestPlayers = (nrOfPlayers, eventId) => {
  return async (dispatch, getState) => {
    const state = getState()

    if (state.eventPage.status === 'loading')
      return

    dispatch(addPlayerStarted())
    const body = {nrOfPlayers: nrOfPlayers}
    
    try {
      const response = await fetch(`${SERVER_URL}/events/${eventId}/players/add_test_players`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
        credentials: 'include',
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const parsed_response = await response.json();
      dispatch(addPlayerSucceeded(parsed_response.data));
    } catch (error) {
      dispatch(addPlayerFailed(error.message));
    }
  };
}

export const deletePlayer = (playerId, eventId) => {
  return async (dispatch) => {
    try {

      const response = await fetch(`${SERVER_URL}/events/${eventId}/players/${playerId}`, {
        method: 'DELETE',
        credentials: 'include',
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const parsed_response = await response.json();
      console.log(parsed_response)
      dispatch(deletePlayerSucceeded(parsed_response.data));
    } catch (error) {
      dispatch(deletePlayerFailed(error.message));
    }
  };
}

