import { Form, Button, Row, Col } from 'react-bootstrap';
import { login } from '../../actions/users'
import { useDispatch, useSelector } from 'react-redux'
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import logo from '../../images/logo.png'

export const Login = () => {

  const [phone, setPhone] = useState();
  const [password, setPassword] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated); // Check if the user is authenticated
  const error = useSelector((state) => state.auth.error)
  const shouldRedirectToDashboard = useSelector((state) => state.auth.shouldRedirectToDashboard); // Check the flag for redirection

  const isMobile = window.innerWidth < 768;

  const handleLogin = (e) => {
    e.preventDefault();
  
    const user = { user: {phone, password} }  
    dispatch(login(user));
  }

  useEffect(() => {
    if (isAuthenticated && shouldRedirectToDashboard) {
      navigate('/');
    }
  }, [isAuthenticated, shouldRedirectToDashboard, navigate]);

  return (
    <Row className='login-container'>
      <Col className='center-content'>
        <Form className='login-form'>
          <div className='xxl-text primary-color padding-bottom-1 margin-bottom-1 border-bottom-white-1 white full-width'>
            Logare
          </div>
          <Form.Label className='label large-text white' >Numar de telefon</Form.Label>
          <Form.Control size='md' className='input' onChange = {(e) => {setPhone(e.target.value)}}></Form.Control>
          <Form.Label className='label large-text white' >Parola</Form.Label>
          <Form.Control size='md' className='input' type='password' onChange = {(e) => {setPassword(e.target.value)}}></Form.Control>
          {error && <div className='error-message'>{error}</div>}
          <Button type='submit' className='submit-inverted bold' onClick={handleLogin}>Logare</Button>
          <Row className="justify-content-md-center">
            <a className='bold white' href='/register'>Inregistrare</a>
          </Row>
        </Form>
      </Col>
      {!isMobile && <Col className='image-container'>
        <img src={logo} alt='logo'/>
      </Col>}
    </Row>
  )
}