export const selectCurrentRound = state => state.eventPage.play.rounds[state.eventPage.play.rounds.length - 1]
export const selectRobinRounds = state => state.eventPage.play.rounds.filter(round => round.rules === 'snake')
export const selectBracketRounds = state => state.eventPage.play.rounds.filter(round => round.rules === 'brackets')
export const selectRounds = state => state.eventPage.play.rounds
export const selectQualifiedPlayers = (state, roundId) => {
  const rounds = state.eventPage.play.rounds;
  if (!rounds || rounds.length === 0) {
    return [];
  }
  const round = rounds.find(round => round.id === roundId);

  const qualifiedPlayers = round ? round.groups.flatMap(group => group.instances.filter(instance => instance.qualified)) : [];
  return qualifiedPlayers ? qualifiedPlayers : [];
}