import { ListGroup, ListGroupItem, Row, Col, Form } from "react-bootstrap"
import { useState, useEffect } from "react"
import { useDispatch } from "react-redux";
import { ButtonGroup, Button } from "@mui/material";
import { enterScore, fetchGroupData } from "../../../../../actions/event_page/play_tab/groups";
import { BracketMatch } from "./bracket_match";

export const BracketPlayGroup = ({group, isQualified, readOnly, disabled}) => {

  const match = group.matches ? group.matches[0] : {}

  const dispatch = useDispatch()

  const setScore = (player1Score, player2Score) => {
    dispatch(enterScore(match.id, player1Score, player2Score, group))
  }

  useEffect(() => {
    dispatch(fetchGroupData(group.roundId, group.id))
  }, [dispatch, group.roundId, group.id])

  return match && (
    <BracketMatch match={match} setScore={setScore} isQualified={isQualified} players={group.instances} disabled={disabled} readOnly={readOnly}/>
  );
}