import { SERVER_URL } from "../../../server_config";

import { fetchRounds } from './rounds';

export const FETCH_GROUPS_STARTED = 'FETCH_GROUPS_STARTED'
export const FETCH_GROUPS_SUCCEEDED = 'FETCH_GROUPS_SUCCEEDED'
export const FETCH_GROUPS_FAILED = 'FETCH_GROUPS_FAILED'
export const FETCH_GROUP_DATA_STARTED = 'FETCH_GROUP_DATA_STARTED'
export const FETCH_GROUP_DATA_SUCCEEDED = 'FETCH_GROUP_DATA_SUCCEEDED'
export const FETCH_GROUP_DATA_FAILED = 'FETCH_GROUP_DATA_FAILED'

export const ENTER_SCORE_SUCCEEDED = 'ENTERS_SCORE_SUCCEEDED';
export const ENTER_SCORE_FAILED = 'ENTER_SCORE_FAILED';
export const ENTER_SCORE_STARTED = 'ENTER_SCORE_STARTED';

export const QUALIFY_PLAYER_STARTED = 'QUALIFY_PLAYER_STARTED';
export const QUALIFY_PLAYER_SUCCEEDED = 'QUALIFY_PLAYER_SUCCEEDED';
export const QUALIFY_PLAYER_FAILURE = 'QUALIFY_PLAYER_FAILURE';
export const DISQUALIFY_PLAYER_STARTED = 'DISQUALIFY_PLAYER_STARTED';
export const DISQUALIFY_PLAYER_SUCCEEDED = 'DISQUALIFY_PLAYER_SUCCEEDED';
export const DISQUALIFY_PLAYER_FAILURE = 'DISQUALIFY_PLAYER_FAILURE';


export const fetchGroupsStarted = () => ({
  type: FETCH_GROUPS_STARTED
})

export const fetchGroupsSucceeded = (groups, roundId) => ({
  type: FETCH_GROUPS_SUCCEEDED,
  groups: groups,
  roundId: roundId,
})

export const fetchGroupsFailed = (error) => ({
  type: FETCH_GROUPS_FAILED,
  error
})

export const fetchGroupDataStarted = () => ({
  type: FETCH_GROUP_DATA_STARTED
})

export const fetchGroupDataSucceeded = (group) => ({
  type: FETCH_GROUP_DATA_SUCCEEDED,
  group
})

export const fetchGroupDataFailed = (error) => ({
  type: FETCH_GROUP_DATA_FAILED,
  error
})

const enterScoreSucceeded = () => {
  return {
    type: ENTER_SCORE_SUCCEEDED,
  }
}

export const enterScoreFailed = (error) => ({
  type: ENTER_SCORE_FAILED,
  error
})

export const enterScoreStarted = () => ({
  type: ENTER_SCORE_STARTED,
})

export const qualifyPlayerStarted = () => ({
  type: QUALIFY_PLAYER_STARTED
})

export const qualifyPlayerSucceeded = qualifiedPlayer => ({
  type: QUALIFY_PLAYER_SUCCEEDED,
  qualifiedPlayer
})

export const qualifyPlayerFailed = error => ({
  type: QUALIFY_PLAYER_FAILURE,
  error
})

export const disqualifyPlayerStarted = () => ({
  type: DISQUALIFY_PLAYER_STARTED
})

export const disqualifyPlayerSucceeded = disqualifiedPlayer => ({
  type: DISQUALIFY_PLAYER_SUCCEEDED,
  disqualifiedPlayer
})

export const disqualifyPlayerFailed = error => ({
  type: DISQUALIFY_PLAYER_FAILURE,
  error
})


export const confirmGroups = (players, roundId) => {
  return async (dispatch, getState) => {
    const state = getState();
    const eventId = state.eventPage.event.id
    const body = {
      playersData: players,
    };

    try {
      const response = await fetch(`${SERVER_URL}/events/${eventId}/rounds/${roundId}/confirm_groups`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json", 
        },
        credentials: "include",
        body: JSON.stringify(body),
      });

      if (!response.ok) 
        throw new Error('Network response was not ok');

      dispatch(fetchRounds());
    } catch (error){
      console.log(error)
    }
  };
};



export const fetchGroupsForRound = (round) => {
  return async (dispatch) => {
    dispatch(fetchGroupsStarted());
    console.log(round)
    try {
      const response = await fetch(`${SERVER_URL}/events/${round.eventId}/rounds/${round.id}/groups`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const parsedResponse = await response.json();
      dispatch(fetchGroupsSucceeded(parsedResponse.groups, round.id));
      return parsedResponse.groups;
    } catch (error) {
      dispatch(fetchGroupsFailed(error));
      console.log(error);
    }
  };
}

export const fetchGroupData = (roundId, groupId) => {
  return async (dispatch, getState) => {
    const eventId = getState().eventPage.event.id;
    dispatch(fetchGroupDataStarted());

    try {
      const response = await fetch(`${SERVER_URL}/events/${eventId}/rounds/${roundId}/groups/${groupId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const parsedResponse = await response.json();
      dispatch(fetchGroupDataSucceeded(parsedResponse.group));
    } catch (error) {
      dispatch(fetchGroupDataFailed(error));
      console.log(error);
    }
  };
}

export const enterScore = (matchId, score1, score2, group) => {
  return async (dispatch, getState) => {

    dispatch(enterScoreStarted());
    const eventId = getState().eventPage.event.id
    const roundId = group.roundId
    const groupId = group.id

    const body = {score1, score2}
    const url = `${SERVER_URL}/events/${eventId}/rounds/${roundId}/groups/${groupId}/matches/${matchId}`

    try {
      const response = await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json", 
        },
        credentials: "include",
        body: JSON.stringify(body),
      });

      if (!response.ok) 
        throw new Error('Network response was not ok');

      dispatch(enterScoreSucceeded())
      dispatch(fetchGroupData(roundId, groupId));
    } catch (error){
      dispatch(enterScoreFailed(error));
      console.log(error)
    }
  }
}

export const qualifyPlayer = (playerInstance, group) => {
  return async (dispatch, getState) => {

    dispatch(qualifyPlayerStarted());

    const eventId = getState().eventPage.event.id
    const roundId = group.roundId
    const groupId = group.id
    const url = `${SERVER_URL}/events/${eventId}/rounds/${roundId}/groups/${groupId}/player_instances/${playerInstance.id}`
    const body = {qualified: true}

    try {
      const response = await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json", 
        },
        credentials: "include",
        body: JSON.stringify(body),
      });

      if (!response.ok) 
        throw new Error('Network response was not ok');

      dispatch(qualifyPlayerSucceeded(playerInstance));
      dispatch(fetchGroupsForRound({id: roundId, eventId: eventId}));
    } catch (error){
      dispatch(qualifyPlayerFailed(error));
    }
  }
}

export const disqualifyPlayer = (playerInstance, group) => {
  return async (dispatch, getState) => {

    dispatch(disqualifyPlayerStarted());
    const eventId = getState().eventPage.event.id
    const roundId = group.roundId
    const groupId = group.id
    const url = `${SERVER_URL}/events/${eventId}/rounds/${roundId}/groups/${groupId}/player_instances/${playerInstance.id}`
    const body = {qualified: false}

    try {
      const response = await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json", 
        },
        credentials: "include",
        body: JSON.stringify(body),
      });

      if (!response.ok) 
        throw new Error('Network response was not ok');
      dispatch(disqualifyPlayerSucceeded(playerInstance));
      dispatch(fetchGroupsForRound({id: roundId, eventId: eventId}));
    } catch (error){
      console.log(error)
    }
  }
}