import { Tab, Nav } from "react-bootstrap"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { selectRobinRounds, selectBracketRounds, selectCurrentRound } from "../../../../selectors/round"
import { selectEventStage } from "../../../../selectors/event_page"
import { Round } from "./round"
import { fetchRounds } from "../../../../actions/event_page/play_tab/rounds"
import { Brackets } from "./brackets/brackets"
import { LoadingPage } from "../../../shared/loading_page"

export const PlayTab = () => {

  const dispatch = useDispatch()

  const robinRounds = useSelector(selectRobinRounds)
  const bracketRounds = useSelector(selectBracketRounds)
  const currentRound = useSelector(selectCurrentRound)
  const stageId = useSelector(selectEventStage)

  const [activeTab, setActiveTab] = useState()

  useEffect(() => {
    dispatch(fetchRounds())
  }, [dispatch])

  useEffect(() => {
    if (currentRound && robinRounds.length > 0) {
      const tabIndex = robinRounds.findIndex(round => round.id === currentRound.id)
      setActiveTab(tabIndex)
    }
  }, [currentRound, robinRounds])
  
  if (!robinRounds || robinRounds.length === 0 || !currentRound) {
    return <LoadingPage />
  }

  return (
    <div className="dashboard-container">
      <Tab.Container activeKey={activeTab} onSelect={(k) => setActiveTab(k) }>
        <Nav variant="pills">
          {
            robinRounds.map((round, index) => {
              return (
                <Nav.Item key={index}>
                  <Nav.Link eventKey={index}>Runda {index + 1}{round.groupsConfirmed ? ' ' : ' (confirmare grupe)'}</Nav.Link>
                </Nav.Item>
              )
            })
          }
          {
            bracketRounds.length > 0 &&
            <Nav.Item>
              <Nav.Link eventKey={-1}>Tabel Eliminatoriu</Nav.Link>
            </Nav.Item>
          }
        </Nav>
        <Tab.Content className="margin-1">
          {
            robinRounds.map((round, index) => {
              return (
                <Tab.Pane key={index} eventKey={index}>
                  <Round round={round}/>
                </Tab.Pane>
              )
            })
          }
          {
            bracketRounds.length > 0 &&
            <Tab.Pane eventKey={-1}>
              <Brackets rounds={bracketRounds} currentRoundId={currentRound.id} finishedEvent={stageId === 5}/>
            </Tab.Pane>
          }
        </Tab.Content>
      </Tab.Container>
    </div>
  );
} 