import { fetchWithToken } from '../helpers/auth';
import { SERVER_URL } from '../server_config';

export const NEW_CUSTOMER_REQUEST = 'NEW_CUSTOMER_REQUEST';
export const NEW_CUSTOMER_SUCCESS = 'NEW_CUSTOMER_SUCCESS';
export const NEW_CUSTOMER_FAILURE = 'NEW_CUSTOMER_FAILURE';
export const FETCH_CUSTOMER_DATA_SUCCESS = 'FETCH_CUSTOMER_DATA_SUCCESS';
export const FETCH_CUSTOMER_DATA_FAILURE = 'FETCH_CUSTOMER_DATA_FAILURE';
export const FETCH_CUSTOMER_DATA_REQUEST = 'FETCH_CUSTOMER_DATA_REQUEST';
export const EDIT_CUSTOMER_REQUEST = 'EDIT_CUSTOMER_REQUEST';
export const EDIT_CUSTOMER_SUCCESS = 'EDIT_CUSTOMER_SUCCESS';
export const EDIT_CUSTOMER_FAILURE = 'EDIT_CUSTOMER_FAILURE';
export const ADD_MANAGER_SUCCESS = 'ADD_MANAGER_SUCCESS';
export const ADD_MANAGER_FAILURE = 'ADD_MANAGER_FAILURE';
export const ADD_MANAGER_REQUEST = 'ADD_MANAGER_REQUEST';
export const REMOVE_MANAGER_SUCCESS = 'REMOVE_MANAGER_SUCCESS';
export const REMOVE_MANAGER_FAILURE = 'REMOVE_MANAGER_FAILURE';
export const REMOVE_MANAGER_REQUEST = 'REMOVE_MANAGER_REQUEST';

export const newCustomerRequest = () => ({
  type: NEW_CUSTOMER_REQUEST,
});

export const newCustomerSuccess = (user) => ({
  type: NEW_CUSTOMER_SUCCESS,
  user,
});

export const newCustomerFailure = (error) => ({
  type: NEW_CUSTOMER_FAILURE,
  payload: error,
});

export const fetchCustomerDataRequest = () => ({
  type: FETCH_CUSTOMER_DATA_REQUEST,
});

export const fetchCustomerDataSuccess = (customer) => ({
  type: FETCH_CUSTOMER_DATA_SUCCESS,
  customer,
});

export const fetchCustomerDataFailure = (error) => ({
  type: FETCH_CUSTOMER_DATA_FAILURE,
  payload: error,
});

export const editCustomerRequest = () => ({
  type: EDIT_CUSTOMER_REQUEST,
});

export const editCustomerSuccess = (customer) => ({
  type: EDIT_CUSTOMER_SUCCESS,
  customer,
});

export const editCustomerFailure = (error) => ({
  type: EDIT_CUSTOMER_FAILURE,
  payload: error,
});

export const addManagerRequest = () => ({
  type: ADD_MANAGER_REQUEST,
});

export const addManagerSuccess = (newManager) => ({
  type: ADD_MANAGER_SUCCESS,
  newManager,
});

export const addManagerFailure = (error) => ({
  type: ADD_MANAGER_FAILURE,
  payload: error,
});

export const removeManagerRequest = () => ({
  type: REMOVE_MANAGER_REQUEST,
});

export const removeManagerSuccess = (removedManager) => ({
  type: REMOVE_MANAGER_SUCCESS,
  removedManager,
});

export const removeManagerFailure = (error) => ({
  type: REMOVE_MANAGER_FAILURE,
  payload: error,
});

export const createCustomer = ({name, address}) => {
  return async (dispatch, getState) => {
    
    const state = getState();

    if (state.customerDashboard.status === 'loading') {
      return;
    }

    dispatch(newCustomerRequest());

    const customer = {
      name,
      address,
    }
    fetchWithToken(`${SERVER_URL}/customers`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(customer),
    })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        return response.json().then((errorData) => {
          throw new Error(errorData.error);
        });
      }
    })
    .then((data) => {
      const user = data.user;
      dispatch(newCustomerSuccess(user));
      dispatch(fetchCustomerData());
    })
    .catch((error) => {
      dispatch(newCustomerFailure(error.message));
    });
  }
}

export const fetchCustomerData = () => {
  return async (dispatch, getState) => {
    dispatch(fetchCustomerDataRequest());
    const customerId = getState().auth.user.customerId;
    try {
      const response = await fetchWithToken(`${SERVER_URL}/customers/${customerId}`, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          'Accept': 'application/json',
        },
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const responseData = await response.json();

      dispatch(fetchCustomerDataSuccess(responseData.customer)); // Dispatch the data to the Redux store
    } catch (error) {
      dispatch(fetchCustomerDataFailure(error)); // Dispatch the error to the Redux store
    }
  };
};

export const editCustomer = (customerId, name, address) => async (dispatch) => {
  dispatch(editCustomerRequest());

  const customer = {
    name,
    address,
  };

  try {
    const response = await fetch(`${SERVER_URL}/customers`, {
      method: "PUT",
      headers: {
      "Content-Type": "application/json",
      "Accept": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({ id: customerId, ...customer }),
    });

    if (response.ok) {
      const responseData = await response.json();
      dispatch(editCustomerSuccess(responseData.data));
    } else {
      throw new Error("Failed to edit customer.");
    }
  } catch (error) {
    console.error("Error editing customer:", error);
    dispatch(editCustomerFailure("An error occurred while editing customer."));
  }
};

export const addManager = (userId) => async (dispatch, getState) => {
  const state = getState();
  const customerId = state.customerDashboard.customer.id;

  if (state.customerDashboard.status === 'loading') {
    return;
  }

  dispatch(addManagerRequest());

  try {
    const response = await fetchWithToken(`${SERVER_URL}/customers/${customerId}/add_manager`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({ new_manager_id: userId }),
    });

    if (response.ok) {
      const responseData = await response.json();
      dispatch(addManagerSuccess(responseData.newManager));
    } else {
      throw new Error("Failed to add manager.");
    }
  } catch (error) {
    dispatch(addManagerFailure("An error occurred while adding manager."));
  }
}

export const removeManager = (userId) => async (dispatch, getState) => {
  const state = getState();

  if (state.customerDashboard.status === 'loading') {
    return
  }

  const customerId = state.customerDashboard.customer.id; // Access the customer ID from the store

  dispatch(removeManagerRequest());

  try {
    const response = await fetchWithToken(`${SERVER_URL}/customers/${customerId}/remove_manager`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({ manager_id: userId }),
    });

    if (response.ok) {
      const responseData = await response.json();
      dispatch(removeManagerSuccess(responseData.removedManager));
    } else {
      throw new Error("Failed to remove manager.");
    }
  } catch (error) {
    console.error("Error removing manager:", error);
    dispatch(removeManagerFailure("An error occurred while removing manager."));
  }
};

