export async function fetchWithToken(url, options = {}) {
  const token = localStorage.getItem('jwtToken'); // Retrieve the token from local storage

  const headers = {
    'Content-Type': 'application/json',
    ...options.headers,
  };

  if (token) {
    headers['Authorization'] = `Bearer ${token}`; // Add token to headers
  }

  const response = await fetch(url, {
    ...options,
    headers,
  });

  if (!response.ok) {
    let errorMessage = 'Network response was not ok';
    try {
      // Try to parse the error message from the response
      const errorData = await response.json();
      errorMessage = errorData.error || errorMessage; // Use server error message if available
    } catch (error) {
      // If parsing fails, fallback to default error message
      console.error('Error parsing JSON response:', error);
    }
    
    // Forward the status and error message
    throw new Error(`${errorMessage}`);
  }

  return response;
}
