import { SERVER_URL } from "../../../server_config";

export const FETCH_ROUNDS_STARTED = 'FETCH_ROUNDS_STARTED'
export const FETCH_ROUNDS_SUCCEEDED = 'FETCH_ROUNDS_SUCCEEDED'
export const FETCH_ROUNDS_FAILED = 'FETCH_ROUNDS_FAILED'

export const ADD_ROUND_STARTED = 'ADD_ROUND_STARTED'
export const ADD_ROUND_SUCCEEDED = 'ADD_ROUND_SUCCEEDED'
export const ADD_ROUND_FAILED = 'ADD_ROUND_FAILED'

export const DELETE_ROUND_STARTED = 'DELETE_ROUND_STARTED'
export const DELETE_ROUND_SUCCEEDED = 'DELETE_ROUND_SUCCEEDED'
export const DELETE_ROUND_FAILED = 'DELETE_ROUND_FAILED'

export const END_TOURNAMENT = 'END_TOURNAMENT'

export const fetchRoundsStarted = () => ({
  type: FETCH_ROUNDS_STARTED
})

export const fetchRoundsSucceeded = (rounds) => ({
  type: FETCH_ROUNDS_SUCCEEDED,
  rounds
})

export const fetchRoundsFailed = (error) => ({
  type: FETCH_ROUNDS_FAILED,
  error
})

export const addRoundStarted = () => ({
  type: ADD_ROUND_STARTED,
})

export const addRoundSucceeded = ( round, eventStageId ) => ({
  type: ADD_ROUND_SUCCEEDED,
  round: round,
  eventStageId: eventStageId,
})

export const addRoundFailed = error => ({
  type: ADD_ROUND_FAILED,
  error,
})

export const deleteRoundStarted = () => ({
  type: DELETE_ROUND_STARTED,
})

export const deleteRoundSucceeded = () => ({
  type: DELETE_ROUND_SUCCEEDED,
})

export const deleteRoundFailed = (error) => ({
  type: DELETE_ROUND_FAILED,
  error
})

export const endTournament = (winner) => ({
  type: END_TOURNAMENT,
  winner: winner,
})

export const fetchRounds = () => {
  return async (dispatch, getState) => {
    dispatch(fetchRoundsStarted());
    const eventId = getState().eventPage.event.id;
    try {
      const response = await fetch(`${SERVER_URL}/events/${eventId}/rounds`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const parsedResponse = await response.json();

      dispatch(fetchRoundsSucceeded(parsedResponse.rounds));
    } catch (error) {
      dispatch(fetchRoundsFailed(error));
      console.log(error);
    }
  };
}


export const addRound = (round) => {
  return async (dispatch, getState) => {
    const body = round;
    const state = getState();

    if (state.eventPage.status === 'loading')
      return;

    dispatch(addRoundStarted())

    try {
      const response = await fetch(`${SERVER_URL}/events/${round.eventId}/rounds`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json", 
        },
        credentials: "include",
        body: JSON.stringify(body),
      });

      if (!response.ok) 
        throw new Error('Network response was not ok');
      
      const parsed_response = await response.json();

      console.log(parsed_response)
      if (parsed_response.winner)
        dispatch(endTournament(parsed_response.winner));
      else
        dispatch(addRoundSucceeded(parsed_response.round, parsed_response.eventStageId));
    } catch (error){
      dispatch(addRoundFailed(error));
    }
  }
}

export const deleteRound = (roundId) => {
  return async (dispatch, getState) => {
    const state = getState();
    const eventId = state.eventPage.event.id;

    try {
      const response = await fetch(`${SERVER_URL}/events/${eventId}/rounds/${roundId}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json", 
        },
        credentials: "include",
      });

      if (!response.ok) 
        throw new Error('Network response was not ok');

      dispatch(deleteRoundSucceeded());
      dispatch(fetchRounds());
    } catch (error){
      dispatch(deleteRoundFailed(error));
    }
  }
}

export const nextRound = (round) => {
  return async (dispatch, getState) => {
    const state = getState();

    if (state.eventPage.status === 'loading')
      return;
    const body = round
    const eventId = state.eventPage.event.id

    dispatch(addRoundStarted())
    try {
      const response = await fetch(`${SERVER_URL}/events/${eventId}/rounds/next_round`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json", 
        },
        credentials: "include",
        body: JSON.stringify(body),
      });

      if (!response.ok) 
        throw new Error('Network response was not ok');
      
      const parsedResponse = await response.json();

      if (parsedResponse.winner)
        dispatch(endTournament(parsedResponse.winner));
      else
        dispatch(addRoundSucceeded(parsedResponse.round, parsedResponse.eventStageId));
    }
    catch (error){
      dispatch(addRoundFailed(error));
    }
  }
}