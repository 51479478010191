import { 
  FETCH_CUSTOMER_DATA_FAILURE,
  FETCH_CUSTOMER_DATA_REQUEST,
  FETCH_CUSTOMER_DATA_SUCCESS,
  EDIT_CUSTOMER_FAILURE,
  EDIT_CUSTOMER_REQUEST,
  EDIT_CUSTOMER_SUCCESS,
  ADD_MANAGER_SUCCESS,
  ADD_MANAGER_FAILURE,
  ADD_MANAGER_REQUEST,
  REMOVE_MANAGER_SUCCESS,
  REMOVE_MANAGER_FAILURE,
  REMOVE_MANAGER_REQUEST,
  NEW_CUSTOMER_REQUEST,
  NEW_CUSTOMER_FAILURE,
  NEW_CUSTOMER_SUCCESS,
} from "../../actions/customer_dashboard"

import {
  POTENTIAL_MANAGER_SUCCESS,
  POTENTIAL_MANAGER_REQUEST,
  POTENTIAL_MANAGER_FAILURE,
  POTENTIAL_MANAGER_RESET,
} from "../../actions/users"

const initialState = {
  status: 'uninitialized',
  error: null,
  redirectToCustomerDashboard: false,
  customer: {},
  potentialManager: {
    status: 'uninitialized',
    error: null,
    user: null,
  }
}

const customerDashboardReducer = (state = initialState, action) => {
  switch(action.type) {
    case NEW_CUSTOMER_REQUEST: {
      return {
        ...state,
        status: 'loading',
      }
    }
    case NEW_CUSTOMER_FAILURE: {
      return {
        ...state,
        status: 'failure',
        error: action.error,
      }
    }
    case NEW_CUSTOMER_SUCCESS: 
      return { 
        ...state, 
        status: 'success',
        redirectToCustomerDashboard: true,
      }
    case FETCH_CUSTOMER_DATA_REQUEST: {
      return {
        ...state,
        status: 'loading',
      }
    }
    case FETCH_CUSTOMER_DATA_SUCCESS: {
      return {
        ...state,
        status: 'success',
        customer: action.customer,
        redirectToCustomerDashboard: false,
      }
    }
    case FETCH_CUSTOMER_DATA_FAILURE: {
      return {
        ...state,
        status: 'failure',
        error: action.error,
      }
    }
    case EDIT_CUSTOMER_REQUEST:
      return {
        ...state,
        status: 'loading',
        error: null,
      };
    case EDIT_CUSTOMER_SUCCESS:
      return {
        ...state,
        status: 'success',
        customer: action.customer,
      }
    case EDIT_CUSTOMER_FAILURE:
      return {
        ...state,
        status: 'failure',
        error: action.payload,
      };
    case POTENTIAL_MANAGER_REQUEST:
      return {
        ...state,
        potentialManager: {
          ...state.potentialManager,
          status: 'loading',
          error: null,
        }
      };
    case POTENTIAL_MANAGER_SUCCESS:
      return {
        ...state,
        potentialManager: {
          status: 'success',
          user: action.payload,
          error: null,
        }
      };
    case POTENTIAL_MANAGER_FAILURE:
      return {
        ...state,
        potentialManager: {
          ...state.potentialManager,
          status: 'failure',
          error: action.payload,
        }
      };
    case POTENTIAL_MANAGER_RESET:
      return {
        ...state,
        status: 'success',
        potentialManager: {
          status: 'uninitialized',
          error: null,
          user: null,
        },
      };
    case ADD_MANAGER_SUCCESS:
      return {
        ...state,
        status: 'success',
        customer: {
          ...state.customer,
          managers: [...state.customer.managers, action.newManager],
        },
      };
    case ADD_MANAGER_FAILURE:
      return {
        ...state,
        status: 'failure',
        error: action.payload,
      };
    case ADD_MANAGER_REQUEST:
      return {
        ...state,
        status: 'loading',
        error: null,
      };
    case REMOVE_MANAGER_SUCCESS:
      return {
        ...state,
        status: 'success',
        customer: {
          ...state.customer,
          managers: state.customer.managers.filter(manager => manager.id !== action.removedManager.id),
        }
      };
    case REMOVE_MANAGER_FAILURE:
      return {
        ...state,
        status: 'failure',
        error: action.payload,
      };
    case REMOVE_MANAGER_REQUEST:
      return {
        ...state,
        status: 'loading',
        error: null,
      };
    default:
      return state
    
  }
}

export default customerDashboardReducer