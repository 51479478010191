import { Container, Row } from "react-bootstrap"
import { EventInfo } from "./event_info"
import { PlayersInfo } from "./players_info"
import { SignUpInfo } from "./sign_up_info"
import { useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { selectEventData, selectEventDataStatus } from "../../../selectors/event_page"
import { fetchEventData } from "../../../actions/event_page/event_info"
import { LoadingPage } from "../../shared/loading_page"
import RoundsInfo from "./rounds_info"
import { selectConfirmedPlayers, selectRejectedPlayers, selectWaitlistedPlayers } from "../../../selectors/players"

export const EventPage = () => {

  const { eventId } = useParams()
  const dispatch = useDispatch()

  const status = useSelector(selectEventDataStatus)
  const event = useSelector(selectEventData)
  const confirmedPlayers = useSelector(selectConfirmedPlayers)
  const waitlistedPlayers = useSelector(selectWaitlistedPlayers)
  const rejectedPlayers = useSelector(selectRejectedPlayers)

  useEffect(() => {
    dispatch(fetchEventData(eventId))
  }, [dispatch, eventId])

  if (status === 'loading') return <LoadingPage/>
  if (status === 'error') return <div>Error</div>
  if (status === 'success')
    return (
    <Container fluid className="detailed-event-container">
      <Row className="full-height">
        <EventInfo event={event}></EventInfo>
        <PlayersInfo 
          confirmedPlayers={confirmedPlayers}
          maxPlayers={event.maxPlayers}
          rejectedPlayers={rejectedPlayers}
          waitlistedPlayers={waitlistedPlayers}
        />
        { 
          event.eventStageId === 1 
          ?
          <SignUpInfo event={event}></SignUpInfo>
          :
          <RoundsInfo finishedEvent={event.eventStageId === 5} rounds={event.rounds}/>
        }
      </Row>
    </Container>
  )
}