import { ListGroup, ListGroupItem } from "react-bootstrap"
import { ScoresModal } from "./scores_modal";
import { useState } from "react"


export const PlayGroup = ({group, index, isQualified, selectPlayer, disabled, readOnly}) => {

  const [ scoresOpen, setScoresOpen ] = useState(false);
  
  const handleSelect = (player) => {
    selectPlayer(player)
  }
  return(
    <>
      <ListGroup className="group">
        {group.instances.map(player =>
          <ListGroupItem  disabled={disabled || readOnly} className={isQualified(player) ? 'selected' : ''} action={!disabled} onClick={() => handleSelect(player)}>
            <div className="medium-text">
              {player.player.name}
            </div>
            <div className="pull-right small-text">
              {player.player.city}
            </div>
          </ListGroupItem>
      )}
      </ListGroup>
      <ListGroup className="margin-bottom-1">
          <ListGroupItem action={true} onClick={() => {setScoresOpen(!scoresOpen)}}>
          <div className="medium-text bold">Meciuri si Clasament</div>
        </ListGroupItem>
      </ListGroup>
      <ScoresModal show={scoresOpen} readOnly={readOnly} handleClose={() => {setScoresOpen(false)}} group={group} index={index}/>
    </>
  )
}