import { SERVER_URL } from '../server_config';
import { fetchWithToken } from '../helpers/auth';

export const FETCH_EVENTS_STARTED = 'FETCH_EVENTS_STARTED'
export const FETCH_EVENTS_SUCCEEDED = 'FETCH_EVENTS_SUCCEEDED'
export const FETCH_EVENTS_FAILED = 'FETCH_EVENTS_FAILED'
export const SELF_REGISTER_PLAYER_SUCCEEDED = 'SELF_REGISTER_PLAYER_SUCCEEDED'
export const SELF_REGISTER_PLAYER_FAILED = 'SELF_REGISTER_PLAYER_FAILED'
export const SELF_REGISTER_PLAYER_REQUEST = 'SELF_REGISTER_PLAYER_REQUEST'

export const CHANGE_FILTERS = 'CHANGE_FILTERS'
export const RESET_ERROR_STATE = 'RESET_ERROR_STATE'

export const fetchEventsStarted = () => ({
  type: FETCH_EVENTS_STARTED
})

export const fetchEventsSucceeded = events => ({
  type: FETCH_EVENTS_SUCCEEDED,
  events
})

export const fetchEventsFailed = error => ({
  type: FETCH_EVENTS_FAILED,
  error
})


export const changeFilters = filters => ({
  type: CHANGE_FILTERS,
  filtersData: filters,
})

export const resetErrorState = () => ({
  type: RESET_ERROR_STATE
})

export const selfRegisterPlayerSucceeded = (data, eventId) => ({
  type: SELF_REGISTER_PLAYER_SUCCEEDED,
  data: data,
  eventId: eventId,
})

export const selfRegisterPlayerFailed = (error) => ({
  type: SELF_REGISTER_PLAYER_FAILED,
  error
})

export const selfRegisterPlayerRequest = () => ({
  type: SELF_REGISTER_PLAYER_REQUEST,
})


export const fetchEvents = (filters = {}) => {
  return async dispatch => {
    dispatch(changeFilters(filters));
    dispatch(fetchEventsStarted());

    const queryString = Object.keys(filters)
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(filters[key]))
      .join('&');

    try {
      const url = `${SERVER_URL}/events` + (queryString ? `?${queryString}` : '');

      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch events');
      }

      const events = await response.json();
      dispatch(fetchEventsSucceeded(events.data)); // Dispatch an action with the fetched data
    } catch (error) {
      dispatch(fetchEventsFailed(error.message)); // Dispatch an action for fetch failure
    }
  };
};


export const selfRegisterPlayer = (player) => {
  return async (dispatch, getState) => {
    const state = getState()

    if (state.homePage.status === 'loading' || state.eventPage.status === 'loading')
      return

    dispatch(selfRegisterPlayerRequest())
    const body = {
      player: player,
      selfRegisteredPlayer: true,
    }

    const eventId = player.eventId
    // why is this here?
    fetch(`${SERVER_URL}/events/${eventId}/players`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body)
    })
    .then(response => {
      if (response.status === 409) {
        return response.json().then(data => {
          throw new Error(data.message);
        });
      }
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(response => { 
      dispatch(selfRegisterPlayerSucceeded(response.data, eventId))
    })
    .catch(error => {
      dispatch(selfRegisterPlayerFailed(error.message))
    });
  }
}
